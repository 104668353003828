<template>
  <v-dialog v-model="showDialog" persistent width="550">
    <v-card class="py-10 px-5">
      <v-icon
        class="mt-20"
        size="128"
        style="align-self: center; color: #ffd68a"
      >
        mdi-alert-circle-outline
      </v-icon>

      <v-expand-transition>
        <div class="text-h6 grey--text text--darken-1 mt-5 mb-7">
          Se cambio el cliente desde otra pestaña, serás redirigido al Inicio.
        </div>
        <div>{{error}}</div>
      </v-expand-transition>

      <v-btn class="red-button" width="100px" @click="closeModal">OK</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "ValidateCustomer",
  props: ["order_id","channel_order"],
  data: () => ({
    showDialog: false,
    data: null,
    error: null,
  }),
  async mounted() {
    window.addEventListener("storage", this.onValidate);
    if(this.order_id !=  null){
      this.handler();
    }
  },
  methods: {
    onValidate(event) {
      if (event.key == "currentCustomerCode" && !this.showDialog) {
        this.showDialog = true;
      }
    },
     async handler(){
      const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
      const customer_code =  parseInt(currentCustomerInfo?.KUNNR,10)

     const params = {
        channel: this.channel_order,
        order_id: this.order_id,
        customer_code: customer_code,
      }

      console.log(params);
     try {
        const response = await axios.post("/get-backorders-customer-val", params);

        if(response.data  ==  false){
          this.showDialog = true;
        }

      } catch (error) {
        this.error = "No se pudo obtener la validacion";
      }
    },
    closeModal() {
      this.$router.push({ name: "Home" });
    },
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onValidate);
  },
};
</script>
