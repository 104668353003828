<template>
    <div>
        <div class="text-left pl-3" style="background-color: #000; color:#fff">
            Participacion / Divisiones - {{ charTitle }}
           </div>
          <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
            <v-icon size="20" color="#fff" @click="closeModal()">mdi-close-circle</v-icon>
          </div>
          <div class="pa-3">
          <v-row>
            
            <v-col cols="12" sm="5"> 
              <div class="text-left pa-1 pa-sm-0">
                Trimestre
              <custom-select style=" width: 150px" :options="periods" 
              disableds="loading"
              :disabled="load"
               :placeholder="period"
                  v-model="period"></custom-select>
              </div> 
              <v-progress-circular v-if="load"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <PieChart v-if="!load" :chartdata="pieData" style="height: 230px;"></PieChart> 
              
            </v-col>
            <v-col cols="12" sm="7">
              
          <v-simple-table dense>
            <template v-slot:default>
              <thead class="table-heading " >
                <tr>
                  <th class="text-center" style=" color:#00B99D;">Facturación<br>bruta</th>
                  <th class="text-center text-dark ">
                    <span  style="color:#FF9F00">
                      %&nbsp;de<br>participación
                    </span>
                  </th>
                  <th class="text-center text-dark ">
                  <span  style="color:#746EE5">
                    División
                  </span>
                  </th>
                </tr>
              </thead>
              <tbody class="custom-table-bordered"  v-if="!load">
                <tr v-for="(item,index) in charData" :key="index" :class="index%2==0?'cell-bg-gray':''">
                  <td >{{ formatNumber(item?.NETWR1) }}</td>
                  <td >{{formatFloat(item?.PORCE) }}%</td>
                  <td >{{ item.DIVI}}</td>
                </tr>
                <tr v-if="total > 0">
                  <td> {{ formatNumber(total,"$",0) }}</td>
                  <td>{{ formatFloat(totalP,0) }}%</td>
                  <td>Total</td>
                </tr>
                <tr v-if="charData.length === 0">
                <td colspan="3">
                  <span class="text--disabled"> No se encontraron datos </span>
                </td>
                </tr> 
              </tbody>
              <tbody v-if="load" >
                <tr>
                <td colspan="3">
                  <v-progress-linear class="mt-3" indeterminate color="teal"
      ></v-progress-linear> <span class="text--disabled"> Cargando información</span>
                </td>
                </tr> 
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row> 
          </div>
    </div>
</template>
<script>

import PieChart from "../views/seller/components/PieChart.vue";
import CustomSelect from "./CustomSelect.vue";
import mixinService from  '@/mixins/service.js'; 
import mixnNumbersFormat from "@/mixins/numbersFormats";

export default({
  watch: {
    period(period){   
       this.$emit('periodChanged',period)
    },
    charData(){  
      this.getDataChart('Facturacion por división') 
    }
  },

    name:"ChartBillingByDiv",
    mixins:[mixinService,mixnNumbersFormat],
    components:{
      PieChart,
      CustomSelect},
    props:{
      charData: {
     /* default: () => {
        return [];
      } */ 
      },
      defaultPeriod:{  
        default(){
          let currentMonth =  new Date().getMonth()+1; 
          let trim = Math.ceil(currentMonth/3);
          let currentYear = new Date().getFullYear();
          return (trim+"-"+currentYear)  
        }
      },
      load:{
        default:false
      },
      charTitle:{
        default(){
          return 'Detalle de zona'
        }
      }
    }, 
    computed:{
    

    },
    data: () => ({ 
        period:"",
        periods: [],
        pieData:{}, 
        total:0,
        totalP:0,
      //  loading:false
    }),  
    methods:{  
        ini(){
          this.periods = this.getQuartersList(10);
          this.period =   this.periods[0].value;   
        },
        getDataChart(){   
        this.pieData={};
        const pluck = (arr,key) => arr.map(i => parseFloat(i[key]).toFixed(2));
        const labels = (arr,key) => arr.map(i => i[key]);
        this.total = this.arraySum (pluck(this.charData, 'NETWR1'));
        this.totalP = this.arraySum (pluck(this.charData, 'PORCE'));
      
        const percent = pluck(this.charData, 'PORCE'); 
        const labl = labels(this.charData, 'DIVI');   
        const colors = ['#FF9F00','#00B99D','#746EE5','#CD6155','#AF7AC5','#1F618D','#F5B041','#0B5345','#CA6F1E','#7D6608'];
        this.pieData= {   
            datasets: [
                      {
                        backgroundColor:colors,
                        data: percent,
                      
                      }
                    ],
            labels:labl
                }
         // this.loading=false
        },
        numberformat(number=0){ 
          let result = Number(number).toFixed(2);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }, 
    closeModal()
    {
        this.$emit('closeModal',true)
    },
},
    mounted() {
    let self = this; 
    self.ini();
  },
})
</script>