<template>
    <div>
        <v-dialog v-model="showDialog" :max-width="900">
            <v-card>
                <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
                    <div class="text-left ml-3 mt-1">{{ title }}</div>
                    <v-btn v-if="!loading" icon @click="showDialog = false" class="ml-auto">
                        <v-icon size="20" color="#fff">mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <cumulative-growth :data="billing" :loading="loading" />
                <div v-if="!loading" class="mt-3">
                    <v-btn @click="showDialog = false" dense class="success">
                        Aceptar
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";
import CumulativeGrowth from '@/components/CumulativeGrowth.vue'

export default ({
    props: {
        title: {
            default: 'Detalle de crecimiento acumulado'
        },
        period: {
            default: 202401
        },
        employeeChannel: {
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        employee: {
            default: ''
        },
        role: {
            default: 0
        }
    },
    components: { CumulativeGrowth },
    mixins: [MixinHelpers],
    name: "CumulativeGrowthModal",
    data() {
        return {
            loading: true,
            billing: [],
            refreshData: true,
        }
    },
    watch: {
        showDialog(value) {
            if (value) this.loadData()
        },
        period(value) {
            if (value && this.employeeChannel && this.employee) {
                this.loadData()
            }
        }
    },
    methods: {
        loadData() {
            var params = {
                channel: this.employeeChannel,
                period: `${this.period}`,
                role: this.role
            };
            if (this.role > 0 ) {
                params.user = this.employee;
            } 
            this.cumulativeGrowthD(params);
        },
        async cumulativeGrowthD(params) {
            this.loading = true;
            try {
                const res = await axios.post("/daily-director/cumulative-growth", params);
                //Cuando solo viene un elemento
                if (res.data.FACACT.FACACT?.MESFAC) {
                    res.data.FACACT.FACACT = [res.data.FACACT.FACACT];
                }
                if (res.data.FACANT.FACANT?.MESFAC) {
                    res.data.FACANT.FACANT = [res.data.FACANT.FACANT];
                }
                this.billing = res.data;
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.loading = false;
            }
        },
        dateFormat(date) {
            const month = date.substring(4);
            const year = date.substring(0, 4);
            return `${month}/${year}`;
        },
    },
    computed: {
        showDialog: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value
            }
        }
    },

})
</script>

<style scoped>
.resume-foot {
    background: #FFD75E 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #DDE1E6;
}

.billing-total {
    background-color: #fff;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75)
}
</style>