<template>
  <div>
      <template v-if="loading">
        <v-skeleton-loader  elevation="2" type="table-heading, article, table-tfoot" ></v-skeleton-loader>
      </template>
      <v-simple-table v-else class=" my-table pb-0 mb-0" dense>
                <template v-slot:default>
                  <tbody  class="custom-table-bordered"> 
                  <tr>
                    <td class="custom-td-header">% Pagos en línea</td>
                    <td class="custom-td-header">Mes</td>
                    <td v-for="(date, index) in months" :key="index" class="custom-td-header">{{date.date}}</td> 
                  </tr>
                  <tr>
                    <td rowspan="10"><b class="text-black">
                      <v-tooltip bottom class="align-self-center">
                            <template v-slot:activator="{ on, attrs }">
                              <span  v-bind="attrs"  v-on="on">{{number_format(billing.PPAGL,false)}}%  </span>
                            </template>
                            <span>Montos de pago del mes: <b>${{number_format(billing.MONPAGPER,false)}}</b> <br>
                                  Montos de pago vía IEL: <b>${{number_format(billing.MONPAGIEL,false)}}</b> </span>
                      </v-tooltip>
                    </b> 
                    </td>
                  </tr>
                  <tr v-for="(item,index) in billing.BILLING" :key="index">
                    <td v-html="item.TITULO " class="custom-td-header" />
                    <td  v-for="(month, monthIndex) in months" :key="monthIndex" :class="setItemClass(index, item[getMonthKey(monthIndex)], item.MIN, item.MAX, monthIndex === 0)">
                      <template v-if="hasLink(index)">
                        <a @click="actionLink(index, month)">
                            {{ setFormatItem(index, item[getMonthKey(monthIndex)], item[getPMonthKey(monthIndex)]) }}
                        </a>
                      </template>
                      <template v-else>
                          {{ setFormatItem(index, item[getMonthKey(monthIndex)]) }}
                      </template>
                    </td>
                  </tr>  
                  <tr>
                    <td colspan="2" class="font-weight-bold custom-td-header " >
                      <v-progress-circular v-if="loadingAvgDelivery" indeterminate color="primary" /> Dias habiles tiempo de ciclo de venta 
                    </td>
                    <template v-if="isTrim">
                      <td class="custom-td-header font-weight-bold" v-for="(item,index) in avgDelivery" :key="index">
                        Trim {{item.SPMON}}
                      </td>
                    </template>
                    <template v-else>
                      <td class=""  v-for="(item,index) in avgDelivery" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }}</td>
                    </template>
                  </tr> 
                  <tr>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="d-inline-flex">
                        <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="isTrim" dense>
                          <template v-slot:label>
                            <div class="text-black">Trimestral</div>
                          </template>
                        </v-checkbox>
                        <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="monthly" dense>
                          <template v-slot:label>
                            <div class="text-black">Mensual</div>
                          </template>
                        </v-checkbox>
                      </div>
                    </td>
                    <template v-if="isTrim">
                    <td v-for="(item,index) in avgDelivery" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }}</td>
                    </template>
                  </tr>
                  </tbody>      
                </template>
      </v-simple-table>  
              <v-dialog v-model="showNewProductsHistoryModal" width="auto" :persistent="loadingNewProducts"> 
                  <v-card class="pb-4">    
                      <NewProductsHistory :period="periodNewProductsLabel" :date="months" :data="newPrducts" :loader="loadingNewProducts" v-on:closeModal="closeModal" />  
                  </v-card>
              </v-dialog>

              <v-dialog v-model="prCustomersViaIELModal" width="auto" :persistent="prCustomersViaIELLoader"> 
                  <v-card class="pb-4">   
                    <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
                        <div class="text-left ml-5 mt-1 mr-5" >Detalle de clientes con pagos vía IEL</div> 
                            <v-btn v-if="!prCustomersViaIELLoader" icon @click="prCustomersViaIELModal=false" class="ml-auto">
                                <v-icon size="20" color="#fff" >mdi-close-circle</v-icon>
                            </v-btn> 
                      </div>  
                    <v-simple-table dense class="my-table" v-if="!prCustomersViaIELLoader">
                      <template v-slot:default>
                        <thead> 
                          <tr  class="custom-table-bordered text-center">
                            <th>Número de clientes con pago</th>
                            <th>Clientes con pagos IEL</th> 
                            <th>% clientes con pagos IEL</th> 
                          </tr>
                        </thead>
                        <tbody class="custom-table-bordered">               
                          <tr class=" text-center">
                            <td> {{ number_format(prCustomersViaIELData.CTEPAG) }}</td>
                            <td> {{ number_format(prCustomersViaIELData.PAGIEL) }}</td> 
                            <td> {{ number_format(prCustomersViaIELData.PCTEPAG,false) }}%</td> 
                          </tr>                   
                        </tbody>
                      </template>
                    </v-simple-table>
                    
                      <template v-else>
                        <v-card-actions> 
                            <v-progress-linear class="w-100" indeterminate color="teal" ></v-progress-linear> 
                        </v-card-actions> 
                      </template> 
                  </v-card>
              </v-dialog>
          </div>
</template>

<script> 
import NewProductsHistory from './modals/NewProductsHistory.vue';
import axios from "@/plugins/axios"; 
import MixinService from '@/mixins/service';

export default({
  name:'Billing',
  components:{
    NewProductsHistory, 
},
  mixins: [MixinService],
  props: ['manager','managerData'],
  watch: {
    isTrim(value) {
      this.avgBDaysForDelivery()
    this.monthly = value ? false : true;
  },
  monthly(value) {
    this.isTrim = value ? false : true; 
  },
      async manager(){
        try{
          await this.getBilling();
          await this.avgBDaysForDelivery()
          
          this.$emit('desactiveLoader');
        }catch(error){
          this.$emit('desactiveLoader');
          console.log(error)
        }
      } ,
},
  data: () => ({ 
  showNewProductsHistoryModal: false, 
  monthly: false, 
  isTrim: true, 
  paymentHistory: false, 
  customerPerWeek:false,
  loading:true,
  billing:[],
  months:[],
  newPrducts:[],
  loadingNewProducts:true,
  periodNewProductsLabel:"",
  loadingAvgDelivery:true,
  avgDelivery:[],
  prCustomersViaIELModal:false,
  prCustomersViaIELLoader:false,
  prCustomersViaIELData:[],
}),
created(){
  this.months = this.calcularUltimosMeses(7).reverse(); 
}, 
methods:{
  getMonthKey(monthIndex) {
    const monthKeys = ['MES6M','MES5M','MES4M','MES3M','MES2M','MESANT','MESACT'];
    return monthKeys[monthIndex];
  },
  getPMonthKey(monthIndex) {
    const pMonthKeys = ['PMES6M','PMES5M','PMES4M','PMES3M','PMES2M','PMESANT','PMESACT'];
    return pMonthKeys[monthIndex];
  },
  hasLink(index){
    //5 = prod. nuevos, 7 Pagos IEL
    return index == 5 || index == 7;
  },
  actionLink(index,period){
    switch (index) {
      case 5 : this.showNewProducts(period); break; 
      case 7 : this.prCustomersViaIEL(period); break; 
    }
  },
  prCustomersViaIEL(date){
    var month = date.month<10?`0${date.month}`:date.month; 
    var period =  `${date.year}${month}`; 
    this.prCustomersViaIELLoader=true;
    this.prCustomersViaIELModal=true; 
      axios.post('/manager/home/customers-via-IEL-details',{
          manager: this.managerData.PERNR,
          period:period,
          channel:this.managerData.VTWEG
        }).then((res) => { 
          this.prCustomersViaIELData = res.data; 
          this.prCustomersViaIELLoader = false;
      })
      .catch((err) => { 
            console.log(err);
            this.prCustomersViaIELLoader = false;
          }) ; 
 
  },
  getBilling(){
    this.loading=true;
      axios.post('/manager/home/manager-billing',{
        manager: this.managerData.PERNR,
          channel:this.managerData.VTWEG
        }).then((res) => { 
          this.billing = res.data; 
          this.loading = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ; 
  },
  avgBDaysForDelivery(){
    this.loadingAvgDelivery=true;
    this.avgDelivery = [];
      axios.post('/manager/home/avg-bdays-for-delivery',{
          manager: this.managerData.PERNR,
          channel:this.managerData.VTWEG,
          isQuarter:this.isTrim?1:0
        }).then((res) => { 
          this.avgDelivery = res.data; 
          this.loadingAvgDelivery = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loadingAvgDelivery = false;
          }) ; 
  },
  setItemClass(index,value,min,max,isCurrent=false){

    switch (true) {
      case index < 4 || index > 7:
        if (value == max && value!=0 && !isCurrent) {
            return 'text-success font-weight-bold';
        }
        if (value ==min && !isCurrent) {
          return 'text-danger font-weight-bold'
        }
       break;
      case index == 5 || index == 6:
        return 'text-secondary text-decoration-underline';
      case index == 7:
        return 'text-decoration-underline'
    }
    return ''
  },
  setFormatItem(index,item,percent=0){
    switch (index) {
      case 0: case 1: case 2:  case 8:
        return `$${this.number_format(item)}`;
      case 5: case 6: case 7:
        return `${this.number_format(item,percent)}%`;
      default:
        return item 
    }
  },
  closeModal() {
    this.showNewProductsHistoryModal = false;
  },
  showNewProducts(date,type=0){
    var month = date.month<10?`0${date.month}`:date.month;
    this.periodNewProductsLabel = date.date;
    var period =  `${date.year}${month}`

    this.loadingNewProducts=true;
    const params = {
          manager: this.managerData.PERNR,
          period:period,
          channel:this.managerData.VTWEG,
          type: type //tipo 0 productos nuevos
        } 
    this.showNewProductsHistoryModal = true 
    axios.post('/manager/home/billing/new-products',params).then((res) => { 
          this.newPrducts = res.data; 
          this.loadingNewProducts = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loadingNewProducts = false;
          }) ; 
  }
}

})
</script>