import { render, staticRenderFns } from "./NewShoppingCartPR.vue?vue&type=template&id=fa1438aa&scoped=true&"
import script from "./NewShoppingCartPR.vue?vue&type=script&lang=js&"
export * from "./NewShoppingCartPR.vue?vue&type=script&lang=js&"
import style0 from "../../../public/css/shoppingpr.css?vue&type=style&index=0&id=fa1438aa&prod&scoped=true&lang=css&"
import style1 from "./NewShoppingCartPR.vue?vue&type=style&index=1&id=fa1438aa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa1438aa",
  null
  
)

export default component.exports