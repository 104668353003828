<template>
  <div>
    <v-card elevation="0" flat>
      <v-card-text class="d-flex flex-wrap">
        <v-simple-table class="mt-4 w-100"  height="500px" dense fixed-header>
          <template v-slot:default>
            <thead class="table-heading">
              <tr class="th-auto">
                <th class="text-center text-dark ">
                  <div class="d-flex justify-center ">
                    <div class="align-self-end">
                      <b> Zona</b>
                      <span class="d-flex justify-center ma-0">
                        <sort-by-button class="align-self-end w-100" :data="sellers" column="VORNA"
                            :isString="true" iconUp="mdi-arrow-up" iconDown="mdi-arrow-down" @sortedData="sellers = $event" />
                      </span>
                    </div>
                  </div>
                </th>
                <th v-for="(item, index) in headers" :key="index"
                  class="text-center text-dark custom-border-right pb-3 pt-2">
                  <template v-if="item.col == 'CTECOM0'" >
                    <div class="d-flex flex-column h-75">
                        <div class="d-flex justify-center mt-auto" style="width: 254px;">
                        <b v-html="item.title.replaceAll(' ', '&nbsp;')"></b>
                      </div>
                      <div class="d-flex justify-space-between small justify-center mt-auto">
                          <span class="w-25">  {{ months[0].short_name }} / {{ months[1].short_name }}</span>
                          <span class="w-25"> <b>Prom 3</b> </span>
                          <span class="w-25"> Dif </span>
                          <span class="w-25">{{ months[2]?.short_name }}</span>
                        </div>
                    </div>
                  </template>
                  <div v-else class="d-flex justify-center flex-column pb-3">
                    <b v-html="item.title.replaceAll(' ', '&nbsp;')"></b> 
                          <sort-by-button class="align-self-end w-100" v-if="item.col" :data="sellers" :column="item.col"
                            :isString="true" iconUp="mdi-arrow-up" iconDown="mdi-arrow-down" @sortedData="sellers = $event" />
                  </div>

                </th>
              </tr>
            </thead>
            <tbody v-if="!loading" class="custom-white-table">
              <tr v-for="(seller, index) in sellers" :key="index">
                <td style="border-width: 0px 0px 1px 1px">
                  <span class="d-flex">
                    <span class="mt-3 mr-n2 font-weight-bold" style="width:25px">{{ index + 1 }}</span>&nbsp;
                    <v-btn @click="showBillingByDiv(seller.PERNR)" icon>
                      <v-icon color="#B71C1C">mdi-chart-pie</v-icon>
                    </v-btn>
                    <div class="ml-1 mr-1 d-flex align-start flex-column">
                      <div class="text-capitalize font-weight-bold text-decoration-underline">
                        <a @click="showSeller(seller.PERNR)">
                          <v-icon color="red" v-if="isNewSeller(seller.ANTIG)">mdi-alpha-n-circle-outline</v-icon>
                          {{ capitalize(seller.VORNA + ' ' + seller.NACHN + " " + seller.NACH2).replaceAll(' ',
                            '&nbsp;') }}
                        </a>
                      </div>
                      <div>
                        <v-icon>mdi-car</v-icon>
                        <v-icon :color="traffigLightColor(seller.SEMAFO)" size="15">mdi-circle</v-icon>
                        <span style="font-size: 0.7em; font-weight: 750"
                          class="font-weight-bold text-left text-uppercase">
                          {{ `${seller.PERNR} | ${seller.BZIRK}`  }}
                        </span>
                      </div>
                    </div>

                    <div class="text-right ml-auto">
                      <b class="text-decoration-underline " @click="evalFactAnt(seller.PERNR, seller.NHAB)">{{
                        number_format(seller.NHAB) }} h</b> <br />
                      <div v-if="editHabSeller == seller.PERNR" class="d-flex d-inline">
                        <div class="text-left" style="font-size: 12px">
                          <v-btn icon :disabled="UpdatingCitizens" @click="editHabSeller = 0"> <v-icon
                              color="red">mdi-close</v-icon> </v-btn>
                        </div>
                        <div class="d-flex align-items-center ">
                          <v-text-field hide-details="auto" v-model="number_citizen" solo dense
                            :disabled="UpdatingCitizens" style="width:70px"
                            @input="filterNumber(number_citizen)"></v-text-field>
                          <v-btn icon @click="updateSellerDetails(index)" :loading="UpdatingCitizens">
                            <v-icon class="ml-1">mdi mdi-send-circle</v-icon>
                          </v-btn>

                        </div>
                      </div>
                      <b v-else> ${{ number_format(seller.DAT01) }}&nbsp;S/h</b>
                    </div>
                  </span>
                </td> 
                <td>
                  <span class="font-weight-bold font-size-85">
                    <span>{{  number_format(seller.CTESIS) }}&nbsp;/&nbsp;{{ number_format(seller.CTEACT) }}</span>
                    <br />
                    <span class="">{{ number_format(seller.CTEACT1) }}</span>&nbsp;/&nbsp;
                    <span class="">{{ number_format(seller.CTEACT2) }}</span>&nbsp;/&nbsp;
                    <span class="">{{ number_format(seller.CTEACT3) }}</span>
                  </span>
                </td>
                <td>
                  <table class="text-center w-100 custom-table-item-sm">
                    <tr>
                      <td>{{ number_format(seller.CTECOM0) }} / {{ number_format(seller.CTECOM1) }}</td>
                      <td>{{number_format(seller.CTECOM3,false) }}</td>
                      <td>{{number_format( seller.CTECOM3 - seller.CTECOM0) }}</td>
                      <td>{{ seller.CTECOM2 }}</td>
                    </tr>
                  </table>
                </td>
                <td style="font-size: 0.75em" class="font-weight-bold">
                  <span>{{ number_format(seller.PORCTEPED, false) }} %</span>
                </td>
                <td>
                  <span>${{ number_format(seller.PTOMESACT) }}</span>
                </td>
                <td>
                  <span>${{ number_format(seller.PEDMESACT) }}</span>
                </td>
                <td>
                  <span>${{ number_format(seller.FACMESACT) }}</span>
                </td>
                <td>
                  <span>${{ number_format(seller.PTOFACMESACT) }}</span>
                </td>
                <td>
                  <span>${{ number_format(seller.PEDDIAA) }}</span>
                </td>
                <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                  <a class="" @click="cumulativeGrowth(seller.PERNR)">
                    <span>{{ number_format(seller.PORCREC - 100 , false) }}%</span>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">
                  <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <hr /> 
        <p class="mt-3 font-weight-bold text-black">
        <v-icon color="red">mdi-alpha-n-circle-outline</v-icon> Zonas con menos de 12 meses creadas </p>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showModal" width="auto" :persistent="showPopulationInput">
      <v-card class="pb-4">
        <div v-if="showPopulationInput" class="mb-3">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="populationInput" :counter="10" :rules="populationRules" required></v-text-field>
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                Guardar
              </v-btn>
              <v-btn color="warning" @click="resetValidation">
                Cancelar
              </v-btn>
            </v-form>
          </v-card-text>
        </div>

        <PaymentsViaIELSeller v-if="paymentInfo" @closeModal="closeModal()" :period="months[0]" :seller="sellerSelected"
          :modal="showPopulationInput" :channel="chnl" :months="months" /> 
    
      </v-card>

    </v-dialog>

    <cumulative-growth-modal
            :title="`Detalle de crecimiento constante de zona ${sellerSelected}`" 
            v-model = "showCumulativeGrowth"  
            :period = "this.filter.period"
            :employee="sellerSelected"
            :role="3"
            :employeeChannel = "chnl" 
         /> 

    <billing-by-area :employeeNumber="sellerSelected" :employeeChannel="chnl" :period="months[0]"
      v-model="billingByDivModal" :title="`Detalle de zona ${sellerSelected}`" />

  </div>
</template>
<script>
 
 import CumulativeGrowthModal from '@/views/diary/director/modals/CumulativeGrowthModal.vue';
import PaymentsViaIELSeller from '@/views/manager/modals/PaymentsViaIELSeller.vue';
import BillingByArea from '@/components/modals/BillingByArea.vue';
import SortByButton from '@/components/SortByButton.vue';
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers'
import MixinDates from '@/mixins/dates';
import axios from "@/plugins/axios";
export default({
  name:'SellerList',
  mixins: [MixinService, MixinHelpers, MixinDates],
  props: {
    chnl: {
      required: true,
      String
    },
    regionalManager: {
      default: 0
    },
    period: {
      required: true
    },
    filter: {
      required: true
    }
  },
  components: {
    PaymentsViaIELSeller,
    CumulativeGrowthModal,
    BillingByArea,
    SortByButton
  },
  data: () => ({
    valid: true,
    showModal: false,
    showPopulationInput: false,
    populationInput: "",
    paymentInfo: false,
    populationRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        (v && v.length <= 10) || "Este campo debe ser menor a 10 caracteres",
    ],
    months: [],
    sellers: [],
    filteredSellers: [],
    loading: false,
    showCumulativeGrowth: false,
    sellerSelected: 0,
    billingByDivModal: false,
    editHabSeller: 0,
    number_citizen: 0,
    UpdatingCitizens: false,
  }),
  created() {
    this.months = this.calcularUltimosMeses(5);
  },
  mounted() {
    this.loadSellers();
  },
  computed: {
    headers() {
      return [
        { title: `# de clientes <br> sistema / Activos  <br> Clientes nuevos <br> 1/3/6 meses`, col: 'CTESIS' },
        { title: `# de Clientes con compra`, col: 'CTECOM0' },
        { title: '% de <br> clientes con <br> pedidos', col: 'PORPIEL' },
        { title: `Presupuesto <br>de ${this.months[0].full_name}`, col: 'PTOMESACT' },
        { title: `Pedidos de <br>${this.months[0].full_name}`, col: 'PEDMESACT' },
        { title: `Facturación <br>de ${this.months[0].full_name}`, col: 'FACMESACT' },
        { title: `Avance de <br> facturación <br> VS <br>ppto de ${this.months[0].full_name}`, col: 'PTOFACMESACT' },
        { title: 'Pedidos del <br>día anterior', col: 'PEDDIAA' },
        { title: `Crec. Acum. <br>${this.months[1].date}`, col: 'PORCREC' },
      ];
    }
  },
  methods: {
    isNewSeller(seniority){
      return seniority;
    },
    showSeller(seller_id) {
      localStorage.setItem('currentSellerCode', JSON.stringify(seller_id));
      this.$router.push({ name: 'SellerResults' });
    },
    filterNumber(number_citizen) {
      //Evitamos escribir todon lo que no sea números
      this.$nextTick(() => {
        this.number_citizen = number_citizen.replace(/\D/g, "")
      });
    },
    async updateSellerDetails(index) {
      this.UpdatingCitizens = true;
      try {
        if (this.number_citizen != '') {
          let response = await axios.put(`seller/home/update/${this.editHabSeller}`, {
            number_citizen: this.number_citizen
          })
          if (response.data) {
            this.UpdatingCitizens = false;
            this.sellers[index].NHAB = this.number_citizen;
            this.sellers[index].DAT01 = this.number_format(this.sellers[index].FACNETA / this.number_citizen, false);
            this.editHabSeller = 0
          
          }
        }
      } catch (error) {
        this.editHabSeller = 0
        this.UpdatingCitizens = false;
        console.error(error)
      }

    },
    evalFactAnt(seller, value) {
      this.editHabSeller = Number(seller);
      this.number_citizen = value;
    },
    cumulativeGrowth(seller) {
      this.showCumulativeGrowth = true;
      this.sellerSelected = seller;
    },
    showBillingByDiv(seller) {
      this.billingByDivModal = true;
      this.sellerSelected = seller;
    },
    getMonths() {
      var months = this.months;
      return this.implode(this.arrayPluck(months, 'short_name'), ' / ')
    },
    traffigLightColor(value) {
      switch (value) {
        case "1": return "#FF0000";
        case "2": return "orange";
        case "3": return "#0BDA51";
        default: return "gray";
      }
    },
    async loadSellers() {
      const year = this.filter.period.slice(0,4);
      const month = `${this.filter.period.slice(-2)}`;
      this.loading = true;
      try {
        const response = await axios.get('/daily-director/sellers', {
          params: {
            rgManager: this.regionalManager,
            channel: this.chnl,
            month: month,
            year: year,
          }
        });
        this.sellers = response.data;
        this.loading = false;
      } catch (error) {
        console.error(error)
        this.loading = false;
      }
    },

    showCustomersViaIEL(customer) {
      this.sellerSelected = customer;
      this.showModal = true;
      this.paymentInfo = true;
    },
    closeModal() {

      this.showModal = false;
    },
    validate() {
      this.$refs.form.validate();
      this.showPopulationInput = false;
      this.showModal = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.showPopulationInput = false;
      this.showModal = false;
    },
    editPopulation(item) {
      this.paymentInfo = false;
      this.showPopulationInput = true;
      this.populationInput = item;
      this.showModal = true;
    },
  },
  watch:{
    filter(){
      this.loadSellers();
    }
  }

})
</script>

<style scoped>
.th-auto th {
  height: auto !important;
  padding-bottom: 0px !important;
  border-width: 1px !important;
}
</style>