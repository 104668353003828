<template>
  <v-dialog v-model="showDialog" :persistent="loading" width="60vw">
    <v-card class="pb-4 ">
      <div class="d-flex flex-wrap" style="background-color: #000; color:#fff">
        <div class="text-left ml-3 mt-1">{{ title }}</div>
        <v-btn v-if="!loading" icon @click="showDialog = false" class="ml-auto">
          <v-icon size="20" color="#fff">mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-simple-table v-if="!loading" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="6" class="text-center text-dark "> {{ description }}</th>
            </tr>
            <tr class="custom-table-bordered bg-gray">
              <th class="text-center text-dark "><b>Concepto</b></th>

              <th v-for="(item, index) in months" :key="index" class="text-center text-dark ">{{ item.date }}</th>
            </tr>
          </thead>
          <tbody class="custom-table-bordered">
            <tr v-for="(item, index) in data.PAYMENTS" :key="index">
              <td> {{ item.TITLE }} </td>
              <td v-for="(item, i) in item.DATA" :key="i"> {{ paymentTypeFormat(index, item) }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table> 
      <v-progress-circular v-else
      :size="50"
      color="primary"
      class="pa-5 ma-5"
      indeterminate
    ></v-progress-circular>
    </v-card>
  </v-dialog>
</template>

<script>
import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";

export default ({
  props: {
    title: {
      default: '% Clientes con pago vía IEL'
    },
    description: {
      default: 'Detalle de asesor'
    },
    value: {
      type: Boolean,
      default: false
    },
    seller: { default: 0 },
    period: { default: 0 },
    months: { default: true },
    channel: { default: '' },
  },
  mixins: [MixinHelpers],
  data() {
    return {
      loading: true,
      data: [],
      refreshData: true,
    }
  },
  methods: {
    customersViaIEL() {
      this.loading = true;
      var month = this.period.month.toString()
      if (month < 10) { month = `0${month}` }
      const period = `${this.period.year}${month}`
      var params = { seller: this.seller, period: period, channel: this.channel }
      axios.post('/manager/home/sellers/customers-via-iel', params).then((res) => {
        this.data = res.data;
        this.loading = false;
      })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    paymentTypeFormat(index, item) {
      switch (index) {
        case 0:
          return `${this.number_format(item, false)}%`
        default:
          return `${this.number_format(item)}`
      }
    }
  },

  computed: {
    showDialog: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value
      }
    }
  },
  watch: {
    showDialog(value) {
      if (value) this.customersViaIEL();
    }
  },
})
</script>

<style scoped></style>