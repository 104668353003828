<template>
    <div> 
        <v-simple-table dense>
            <template v-slot:default>
                <thead class="" v-if="!loading">
                    <tr class="custom-table-bordered ">
                        <th class="text-center text-body-1 text-dark" style="background-color: #fff;" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} actual</b></th>
                    </tr>
                    <tr class="custom-table-bordered ">
                        <td class="text-center table-heading text-body-2 ">Mes</td>
                        <td class="text-center table-heading text-body-2 ">Facturación</td>
                    </tr>
                </thead>
                <tbody v-show="!loading" class="custom-table-bordered">
                    <tr v-for="(item, index) in data.FACACT?.FACACT" :key="index">
                        <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                            {{dateFormat(item.MESFAC)  }}
                        </td>
                        <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                            ${{ number_format(item.MONFAC) }}
                        </td>
                    </tr>
                    <tr style="">
                        <td colspan="2" style=" " class="table-heading small text-body-2 billing-total">
                            <v-row>
                                <v-col cols="6" class="pa-3">
                                    <b class="ml-n9">Total=</b>
                                </v-col>
                                <v-col cols="6">
                                    <b class="ml-n9"> {{ number_format(data.TOTALFACACT) }}</b>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="loading">
                    <tr>
                        <td colspan="7">
                            <v-progress-linear class="mt-3" indeterminate color="teal"></v-progress-linear> <span class="text--disabled"> Cargando información</span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table dense v-if="!loading">
            <template v-slot:default>
                <thead class="">
                    <tr class="custom-table-bordered ">
                        <th class="text-center text-body-1 text-dark" colspan="7"><b>{{isBilling?'Facturación':'Pedidos'}} Anterior</b></th>
                    </tr>
                    <tr class="custom-table-bordered ">
                        <td class="text-center table-heading text-body-2 ">Mes</td>
                        <td class="text-center table-heading text-body-2 ">Facturación</td>
                    </tr>
                </thead>
                <tbody v-if="!loading" class="custom-table-bordered">
                    <tr v-for="(item, index) in data.FACANT?.FACANT" :key="index">
                        <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading small text-body-2">
                        {{ dateFormat(item.MESFAC)  }}
                        </td>
                        <td :style="index % 2 == 0 ? 'background-color: #fff;' : ''" class="table-heading text-body-2">
                            ${{ number_format(item.MONFAC) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="" class="table-heading small text-body-2 billing-total">
                            <v-row>
                                <v-col cols="6" class="pa-3">
                                    <b class="ml-n9">Total=</b>
                                </v-col>
                                <v-col cols="6">
                                    <b class="ml-n9"> ${{ number_format(data.TOTALFACANT) }} </b>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <tr class="resume-foot">
                        <td colspan="2">
                            <div class="d-flex space-around justify-space-around flex-nowrap">
                                <b> Crecimiento = </b>
                                <b>{{ number_format(data.PORCREC - 100,false) }}%</b>
                            </div> 
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table> 
    </div>
    </template>
    
    <script> 
    
    import MixinHelpers from '@/mixins/helpers'; 
    export default ({ 
        props: { 
            loading: { default: true}, 
            data: { default: []},  
            isBilling: { default: true}, 
        }, 
        mixins: [MixinHelpers],
        name: "CumulativeGrowth",
        data() {
            return {  
                refreshData:true,
            }
        },  
        
        methods: { 
            dateFormat(date){  
            const month = date.substring(4);
            const year = date.substring(0, 4); 
            return `${month}/${year}`; 
                },  
        },
    
    })
    </script>
    
    <style scoped>
    .resume-foot{
        background: #FFD75E 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #DDE1E6;
    }
    .billing-total {
        background-color: #fff;-webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75)
    }
    </style>
    