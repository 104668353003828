<template>  
  <v-card class="elevation-0">
    <div class="text-center text-h5 font-weight-bold">
      <br />
      <span
        ><v-icon color="black">mdi-archive-arrow-down-outline</v-icon>
        HISTORIAL ESTADO DE CUENTA
      </span>
    </div>
    <span class="solid_line"></span>
    <v-card-text class="elevation-0">
      <v-row>
        <v-col md="8" cols="12">
          <date-range-selector class="mt-5" @onClick="getHistoryByDate" />
        </v-col>
        <v-col md="4" cols="12" >
          <v-btn depressed large  block @click="generatePDF" class="mt-6 button-red-round text-white font-weight-bold">
            Estado de Cuenta PDF &nbsp;<v-icon>
              mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear
        :active="loadingOrderDeliveryTime"
        :indeterminate="loadingOrderDeliveryTime"
        top
        color="deep-purple accent-4"
      ></v-progress-linear>
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <search-input
            v-model="searchAccounntStatus"
            class="search-input-width mb-0"
          />
        </v-card-title>
        <account-status-table
          :items="items"
          :search="searchAccounntStatus"
          @onPDFClick="getPDFXMLInvoice"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import { jsPDF } from "jspdf";
import { mapGetters } from "vuex";
import moment from "moment";
import AccountStatusTable from "@/components/tables/AccountStatusTable.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import SearchInput from "@/components/SearchInput.vue";
import axios from "@/plugins/axios";

export default {
  name: "AccountStatusCard",
  props: ["customerCode", "customerGroup"],
  components: {
    AccountStatusTable,
    DateRangeSelector,
    SearchInput,
  },
  data() {
    return {
      loadingOrderDeliveryTime: true,
      items: [],
      searchAccounntStatus: "",
      error: "",
      datadays: {
        DMO00: 0,
        DMO30: 0,
        DMO60: 0,
        DMO90: 0,
        DM120: 0,
      },
      datacustumer: {
        KUNNR: "",
        NAME1: "",
        STCD1: "",
        SMTP_ADDR: "",
        TELF1: "",
      },
      startDate: moment().subtract( 3, 'month').startOf('month').format('YYYY/MM/DD'),
      endDate: moment().format('YYYY/MM/DD'), 
    };
  },
  mounted() {
    this.infoCustomer();
    this.daysPayDue();
    this.$nextTick(function () {
    this.accountStatus(this.startDate,this.endDate);
    });
  },
  methods: {
    getHistoryByDate({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;
      
      this.accountStatus(startDate, endDate);
    },
    async accountStatus(startDate, endDate) {
      this.loadingOrderDeliveryTime = true;

      this.items = []; 

      const params = {
        client: this.customerCode,
        date_start: this.formatDate(startDate),
        date_end: this.formatDate(endDate),
        group_customer: this.customerGroup,
      };
      try {
        const response = await axios.post("/AccountStatus", params);
        this.loadingOrderDeliveryTime = false;
        this.data = response.data;
        let info = this.data.length;
        for (let i = 0; i < info; i++) {
          let amount = this.formatNumber(this.data[i].DMBTR);
          this.items.push({
            currency: this.data[i].WAERS,
            type: this.data[i].TIPO,
            number: parseInt(this.data[i].BELNR),
            purchase_oder: this.data[i].BSTKD,
            date: this.data[i].BUDAT,
            expiration: this.data[i].FAEDT,
            amount: amount,
            actions: this.data[i].BELNR,
            pay_complement: this.data[i].COMPL,
            date_overdue: moment(this.data[i].FVENC).format('DD/MM/YYYY'),
            accuse:this.data[i].ACUSEDOC,
          });
        }
      } catch (error) {
        this.error = "No se pudo obtener el estado de cuenta";
      }
    },
    async daysPayDue() {
      const params = {
        client: this.customerCode,
        vtweg: this.user.VTWEG,
        vkbur: this.user.VKBUR,
      };
      try {
        const response = await axios.post("/daysPayDue", params);
        this.datadays = response.data;
      } catch (error) {
        this.error = "No se pudo obtener Los dias vencidos";
      }
    },
    async infoCustomer() {
      const params = {
        customer: this.customerCode,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      };

      try {
        const response = await axios.post("/infoCustomer", params);
        this.datacustumer = response.data;
      } catch (error) {
        this.error = "No se pudo obtener la información del cliente";
      }
    },
    async getPDFXMLInvoice(invoice, type) {
      const params = {
        invoice: invoice,
        type: type,
      };

      try {
        const response = await axios.post("/InvoicePDFXML", params);
        this.url = response.data;
        if(type == 1){
            const link = "https://docs.google.com/gview?embedded=true&url="+this.url;
            window.open(link, "_blank");
          }else{
            window.open(this.url, "_blank");
          }
      } catch (error) {
        this.error = "No se pudo obtener el estado de cuenta";
      }
    },
    generatePDF() {
      this.$nextTick(function () {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: "letter",
        });

        var img = new Image();
        img.src = "img/header.png";

        var footer = new Image();
        footer.src = "img/footer.png";
        doc.setFontSize(10);
        doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
          },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          columns: [
            { header: "", dataKey: "title" },
            { header: "", dataKey: "content" },
          ],
          body: [
            { title: "Cliente:", content: this.datacustumer.KUNNR },
            { title: "Nombre:", content: this.datacustumer.NAME1 },
            { title: "RFC:", content: this.datacustumer.STCD1 },
            { title: "Email:", content: this.datacustumer.SMTP_ADDR },
            { title: "Teléfono:", content: this.datacustumer.TELF1 },
            {
              title: "Rango Fecha Solicitud:",
              content: moment(this.startDate).format('DD/MM/YYYY') + " / " + moment(this.endDate).format('DD/MM/YYYY'),
            },
          ],
          columnStyles: {
            title: { fontStyle: "bold" },
          },
          margin: { left: 0.5, top: 1.5 },
        });

        doc.autoTable({
          styles: { halign: "center", fillColor: [255, 255, 255] },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          tableLineColor: [169, 169, 169],
          tableLineWidth: 0.01,
          theme: "grid",
          columns: [
            { header: "Total", dataKey: "total" },
            { header: "Por Vencer Mes Actual", dataKey: "toover" },
            { header: "A 30 días", dataKey: "to30days" },
            { header: "A 60 días", dataKey: "to60days" },
            { header: "A 90 días", dataKey: "to90days" },
            { header: "A 120 días", dataKey: "to120days" },
          ],
          body: [
            {
              total: this.datadays.DMO00,
              toover: this.datadays.DMO00,
              to30days: this.datadays.DMO30,
              to60days: this.datadays.DMO60,
              to90days: this.datadays.DMO90,
              to120days: this.datadays.DM120,
            },
          ],
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [169, 169, 169],
            lineWidth: 0.01,
          },
          bodyStyles: {
            lineColor: [169, 169, 169],
            lineWidth: 0.01,
          },
          margin: { left: 0.5, top: 1.5 },
        });

        doc.autoTable({
          styles: { halign: "left" },
          alternateRowStyles: { fillColor: [211, 211, 211] },
          tableLineColor: [211, 211, 211],
          tableLineWidth: 0.01,
          theme: "grid",
          columns: [
            { header: "Tipo Documento", dataKey: "type" },
            { header: "# Documento", dataKey: "number" },
            { header: "Fecha", dataKey: "date" },
            { header: "Vencimiento", dataKey: "date_overdue" },
            { header: "Importe", dataKey: "amount" },
          ],
          body: this.items,
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: [0, 0, 0],
            lineColor: [211, 211, 211],
            lineWidth: 0.01,
          },
          bodyStyles: {
            lineColor: [211, 211, 211],
            lineWidth: 0.01,
            fillColor: [211, 211, 211],
          },
          margin: { left: 0.5, top: 1.5, bottom: 0.5 },
        });

        doc.text(20, 30, "This is some normal sized text underneath.");
        doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);
        doc.save("Estado_de_cuenta.pdf");
      });
    },
    formatNumber(value) {
      const result = Number(value).toFixed(2);
      return this.$options.filters.formatCurrency(result);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style>
.solid_line {
  display: inline-block;
  border: 1px solid #707070 !important;
  width: 97%;
  align-self: center;
  margin: auto;
}
</style>
