<template>
  <div id="contenedor">
    <v-container
      fluid
      class="white lighten-5"
      style="padding-left: 3%; padding-right: 3%"
    >
      <div class="d-flex align-items-center">
        <v-card
          class="mx-auto rounded-lg elevation-4 ma-3 w-100"
          outlined
          :style="'border: 4px solid black; background-color:transparent;'"
        >
          <v-list-item-content>
            <div class="text-general-module mb-0">
              <v-icon @click="videoShow" color="black"
                >mdi-ballot-recount-outline</v-icon
              >
              <span>&nbsp;MOVIMIENTOS DE CUENTA</span>
            </div>
          </v-list-item-content>
        </v-card>
      </div>
      <div>
        <data-customer class="mt-5" :dataClient="dataClient" :loader="loader"></data-customer>      
      </div>
      <v-col md="12" class="mt-2 mb-0">
          <v-row>
          <v-col md="2">
            <v-btn
              depressed
              block
              @click="history"
              class="button-red-small text-white font-weight-bold font-size-8"
            >
              Historial
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
              v-if="paramDiscount == 0"
              depressed
              block
              @click.prevent="toAntPay"
              class="button-green-small text-white font-weight-bold font-size-8"
            >
            Anticipo c/ factura
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
              v-if="paramDiscount == 0"
              depressed
              block
              @click="toPay"
              :disabled="selected.length == 0"
              :class="
                selected.length == 0
                  ? 'button-green-small-disabled '
                  : 'button-green-small'
              "
            >
              <span class="text-white font-weight-bold">Pagar</span>
            </v-btn>
          </v-col>
          <v-col md="4">
            <v-row v-if="bonoShow">
              <v-col md="6">
                <v-btn
                  depressed
                  color="info"
                  block
                  :disabled="invoiceBone == 0 || selected.length == 0 || isSubmitted"
                  :loading="isSubmitted"
                  @click.prevent="bondCard"
                  :class="
                    invoiceBone == 0 || selected.length == 0
                      ? 'button-blue-small-disabled'
                      : 'button-blue-small'
                  "
                  ><span class="text-white font-weight-bold"
                    >Aplicar Bono</span
                  ></v-btn
                >
              </v-col>
              <v-col md="6">
                <v-select
                  solo
                  small
                  dense
                  hide-details
                  label="Selecciona Bono"
                  v-model="bondCurrent"
                  :items="bonds"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:item="{ on, item }">
                    <v-list-item
                      v-on="on"
                      v-bind:class="
                        item.id == 262 && getAuart == false
                          ? 'list-hide-disabled'
                          : ''
                      "
                      >{{ item.name }}</v-list-item
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
            <v-col md="2">
               <search-input
                 v-model="searchAccounntStatus"
                 class="search-input-width"
               />
             </v-col>
        </v-row>
      </v-col>
      <div>
        <v-card class="elevation-0 mt-5" :style="'background-color:transparent;'">
          <v-progress-linear
            :active="loadingBono"
            :indeterminate="loadingBono"
            top
            color="deep-purple accent-4"
          ></v-progress-linear>
          <v-data-table
            v-model="selected"
            :search="searchAccounntStatus"
            :headers="headers"
            :items="discountValidatedFiltered"
            :items-per-page="15"
            show-select
            item-key="number"
            class="elevation-4 table-renglonaje tabla-zebra mt-0"
            :height="discountValidatedFiltered.length < 10 ? 'auto':'490px'"
            fixed-header
          >
            <template v-slot:[`item.number`]="{ item }">
              {{ item.number }}
              <v-tooltip bottom v-if="item.auart == 'PSPO'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img
                      class="ml-2"
                      :src="`/img/account_icons/BONO_POP.svg`"
                      style="width: 20px"
                      alt=""
                    />
                  </span> </template
                ><span>Bono POP</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.typeInv == 'PAGO ANTICIPADO'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img
                      class="ml-2"
                      :src="`/img/account_icons/anticipo_factura.svg`"
                      style="width: 20px"
                      alt=""
                    />
                  </span> </template
                ><span>Pago Anticipado</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.amount) }}
            </template>
            <template v-slot:[`item.dmbtrnc`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.dmbtrnc) }}
            </template>
            <template v-slot:[`item.dmbtrto`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.dmbtrto) }}
            </template>
            <template v-slot:[`item.dmbtrdz`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.dmbtrdz) }}
            </template>
            <template v-slot:[`item.impfacnet`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.impfacnet) }}
            </template>
            <template v-slot:[`item.difbudat`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.difbudat) }}
            </template>
            <template v-slot:[`item.difbudatp`]="{ item }">
              % &nbsp;&nbsp;{{ format(item.difbudatp) }}
            </template>
          </v-data-table>
        </v-card>
      </div>
      <legacy-footer class="mt-10" />
    </v-container>
    <v-dialog v-model="dialog" width="500" persistent>
      <bank-payment-card
        :userChannel="user.VTWEG"
        :customerCode="customerCode"
        :invoice="invoice"
        :typePay="typePay"
        @close="close"
      />
    </v-dialog>
    <v-dialog v-model="dialog3" width="90%">
      <div
        class="w-full bg-black d-flex justify-content-between align-center px-5"
      >
        <span class="text-white d-flex align-center font-weight-bold"
          >Historial estado de cuenta</span
        >
        <v-btn icon small style="bg-gray" @click="dialog3 = false">
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
      <account-status-card
        :customerCode="customerCode"
        :customerGroup="customerGroup"
      />
    </v-dialog>
    <v-dialog v-model="dialogModel" width="500">
      <v-card class="elevation-0">
        <bonus-card :selectedInvoice="invoice" />
        <v-card-actions>
          <v-row class="justify-center">
            <v-col md="3">
              <v-btn
                depressed
                block
                @click="closeModalBonos"
                class="button-red-small text-white font-weight-bold font-size-8"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col md="3">
              <v-btn
                depressed
                block
                @click="toBond"
                class="button-red-small text-white font-weight-bold font-size-8"
              >
                ACEPTAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVideo" width="50%">
     <video-card @closeModa="closeModalVideo"/>
    </v-dialog>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { mapGetters, mapActions } from "vuex";
import AccountStatusCard from "../../components/AccountStatusCard.vue";
import BankPaymentCard from "../../components/BankPaymentCard.vue";
import SearchInput from "@/components/SearchInput.vue";
import LegacyFooter from "@/views/seller/components/LegacyFooter.vue";
import BonusCard from "./components/BonusCard.vue";
import VideoCard from "./components/VideoCard.vue";
import DataCustomer from '@/components/DataCustomer.vue';

import moment from "moment";
import "jspdf-autotable";

export default {
  components: {
    AccountStatusCard,
    BankPaymentCard,
    SearchInput,
    LegacyFooter,
    BonusCard,
    VideoCard,
    DataCustomer, 
  },
  data() {
    return {
      loadingBono: false,
      bank: 0,
      dialog: false,
      dialog3: false,
      dialogVideo: false,
      selected: [],
      bondCurrent: null,
      bonds: [],
      bondCost: [],
      data: null,
      invoice: [],
      dataMovemets: [],
      search: "",
      datacustumer: null,
      token: null,
      invoiceBone: 0,
      today2: moment().format("YYYY/MM/DD"),
      invoiceBoneCurrent: [],
      headers: [
        { text: "Factura", value: "number", visible: true },
        { text: "Fe. Base", value: "date", visible: true },
        { text: "Fe. Vencimiento", value: "expiration", visible: true },
        {
          text: "Impte. Fact.",
          align: "right",
          value: "amount",
          visible: true,
        },
        { text: "DMora", value: "days", visible: true },
        { text: "Estatus", value: "status", visible: true },
        {
          text: "Nota Cred.",
          align: "center",
          value: "vbelnnc",
          visible: true,
        },
        {
          text: "Impte. NCred",
          align: "right",
          value: "dmbtrnc",
          visible: true,
        },
        {
          text: "Docto. Pago",
          align: "center",
          value: "belnrdz",
          visible: true,
        },
        {
          text: "Impte. Pago",
          align: "center",
          value: "dmbtrdz",
          visible: true,
        },
        { text: "DPP", align: "center", value: "difbudat", visible: false },
        { text: "% DPP", align: "center", value: "difbudatp", visible: false },
        {
          text: "Neto a Pagar",
          align: "right",
          value: "impfacnet",
          visible: true,
        },
      ],

      bonoShow: false,
      customerCode: 0,
      customerInfo: [],
      itemCSV: [],
      getAuart: false,
      customerGroup: "",
      typePay: null,
      searchAccounntStatus: "",
      orverdueInvoices: false,
      dialogModel: false,
      paramDiscount: 0,
      dataClient: {},
      loader: true,   
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    discountValidatedFiltered() {
      return this.dataMovemets.filter((r) => {
        if (this.paramDiscount == 0) return true;
        return r.auart == this.paramDiscount;
      });
    },
  },
  watch: {
    selected(value) {
      if (value[0].showSelect) {
        this.$swal({
          title: "NO SE PUEDE SELECCIONAR ESTA FACTURA",
          icon: "error",
          type: "danger",
        });
        return false;
      }
      this.invoice = [];
      this.invoiceBoneCurrent = value;
      this.invoiceBone = value[0].number;
      this.validateSelectedInvoice(value);

      for (let i = 0; i < value.length; i++) {
        this.invoice.push({
          number: value[i].number,
          amount: value[i].impfacnet,
          difbudat: value[i].difbudatp,
          vbelnnc: value[i].vbelnnc,
          belnrdz: value[i].belnrdz,
        });
      }
    },
    dataMovemets: {
      immediate: true,
      handler() {
        this.generarExcel();
      },
    },
  },
  mounted() {

    if (this.$hasAnyRole(["Customer", "CustomerPR"])) {
      this.customerCode = this.user.custumer_code;
      this.type = "CL";
      this.numero_vendedor = this.user.custumer_code;
      this.pathHome = "/";
      this.datacustumer = this.customerInfo;
    } else {
      const currentCustomerInfo =
        JSON.parse(localStorage.getItem("currentCustomerInfo")) || [];
      if (currentCustomerInfo.length < 1) {
        this.$router.push({ name: "Home" });
      }
      this.customerInfo = currentCustomerInfo;
      this.customerCode = parseInt(currentCustomerInfo?.KUNNR, 10);
      this.datacustumer = this.customerInfo;
      this.sellerOrderAviable = false;
      this.type = "VE";
      this.numero_vendedor = this.user.employee_number;
      this.pathHome = "CustomerCoverDetails";
    }

    this.generarPDF();
    this.getCustomerInformation();
    this.getBonds();
    this.getDataClient();

    this.paramDiscount = this.$route.params.disc == "PSPO" ? this.$route.params.disc : 0;

   
    var token = this.$route.query.token;  
    if(token != ""){
      this.getParam1();
    } 

    var nbResponse = this.$route.query.nbResponse;  
    var cdResponse = this.$route.query.cdResponse;  
    var nb_error = this.$route.query.nb_error;  
    if(nbResponse != ""){
      this.getParam2(nbResponse,cdResponse,nb_error);
    }  
 
  },
  methods: {
    ...mapActions({
      sendToPDF: "printer/sendToPDF",
      sendToExcel: "printer/sendToExcel",
    }),
    generarPDF() {
      this.sendToPDF({ active: true });
    },
    generarExcel() {
      let newItems = [];
      this.itemCSV = [];

      for (let key in this.dataMovemets) {
        let item = this.dataMovemets[key];
        newItems.push({
          Factura: item.number,
          "Fe. Base": item.date,
          "Fe.Vencimiento": item.expiration,
          "Impte. Fact.": item.amount,
          "DMora.": item.days,
          "Estatus.": item.status,
          "Nota Cred..": item.vbelnnc,
          "Impte. NCred.": item.dmbtrnc,
          "Docto. Pago.": item.belnrdz,
          "Impte. Pagp.": item.dmbtrdz,
          "Neto a Pagar": item.impfacnet,
        });
      }
      this.itemCSV = newItems;

      let objectCSV = {
        name: `Movimientos-${this.today2}`,
        items: this.itemCSV,
        showLabels: true,
      };

      this.sendToExcel(objectCSV);
    },
    async getDataClient() {        
            try{
                let response = await axios.post("grupo-cliente/data-client", {
                    client: this.customerCode,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    VKBUR: this.user.VKBUR,
                    P_OPCGPO: 0,
                });
                this.dataClient = response.data.dataDetailClient;               
                this.loader = false;
            }catch(error){
                console.log(error);
                this.loader = false;
            }
        },
    validateSelectedInvoice(value) {
      if (this.orverdueInvoices && this.bonoShow && this.getAuart) {
        this.$swal({
          title:
            "USTED TIENE FACTURAS VENCIDAS, NO PUEDE OBTENER EL BENEFICIO DEL DESCUENTO HASTA QUE LIQUIDE LAS FACTURAS VENCIDAS",
          icon: "error",
          type: "danger",
        });
        this.invoiceBone = 0;
      }
      else {
        if (value[0].auart != "PSPO" && this.bondCurrent == 262) {
          this.$swal({
            title:
              "No tienes bonos asignados a esta factura",
            icon: "error",
            type: "danger",
          });
          this.invoiceBone = 0;
        }
      }
    },
    async getBonds() {
      const response = await axios.get(
        "/get-bonds/" + this.customerCode + "/" + this.user.VTWEG
      );
      if (response.data.result == 1) {
        if (response.data.data.length == 0) {
          this.bonoShow = false; // no tienes bonos o hay facturas vencidas
        } else {
          this.bonoShow = true;
          this.bonds = response.data.data;
          this.bondCurrent = response.data.data[0].id;
        }
      }
      // }
    },
    async getCustomerInformation() {
      const params = {
        client: this.customerCode,
        channel: this.user.VTWEG,
      };

      const response = await axios.post("/getExpiredOrders", params);
      this.data = response.data;
      let info = response.data.length;

      for (let i = 0; i < info; i++) {
        if (
          this.data[i].ESTAT == "Vencido" ||
          this.data[i].ESTAT == "VENCIDO"
        ) {
          this.orverdueInvoices = true;
        }

       if (this.data[i].AUART == "PSPO") {
          this.getAuart = true;
        }

        var belnrExplode = false;
        if (this.data[i].BELNR.substring(0, 1) == 6) {
          belnrExplode = true;
        }


        if (this.data[i].IMPFACNET > 0) {
          this.dataMovemets.push({
            type: "FACTURA",
            typeInv: this.data[i].TIPO,
            number: this.data[i].BELNR,
            date: this.data[i].BUDAT,
            expiration: this.data[i].FVENC,
            amount: this.data[i].DMBTR,
            days: this.data[i].DMORA,
            status: this.data[i].ESTAT,
            vbelnnc: this.data[i].VBELNNC,
            dmbtrnc: this.data[i].DMBTRNC,
            dmbtrto: this.data[i].DMBTRTO,
            belnrdz: this.data[i].BELNRDZ,
            dmbtrdz: this.data[i].DMBTRDZ,
            difbudat: this.data[i].DIFBUDAT,
            impfacnet: this.data[i].IMPFACNET,
            difbudatp: this.data[i].DIFBUDATP,
            showSelect: belnrExplode,
            auart: this.data[i].AUART,
            bonds_cont: this.data[i].bonds_cont,
            bonds_ant: this.data[i].bonds_ant,
          });
        }
      }
    },
    toPay() {
      this.selected;
      this.typePay = 1;
      this.dialog = true;
    },
    bondCard() {
      this.dialogModel = true;
    },
    toBond() {
      this.loadingBono = true;
      this.isSubmitted = true;
      this.bondCost = [];
      this.bondCost = this.bonds.filter((b) => b.id == this.bondCurrent);
        axios
          .post("note-credit", {
            client_code: this.customerCode,
            invoice: this.invoice,
            code: this.bondCurrent,
            cost: this.bondCost[0].cost,
            channel: this.user.VTWEG,
          })
          .then((response) => {
            this.loadingBono = false;
            this.isSubmitted = false;
            if (response.data.result == 1) {
              this.$swal({
                title: response.data.message,
                icon: "success",
                type: "succes",
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.loadingBono = false;
              this.$swal({
                title: response.data.data,
                icon: "error",
                type: "danger",
              });
            }
          });
    },
    toAntPay() {
      this.selected;
      this.typePay = 2;
      this.dialog = true;
    },
    history() {
      this.dialog3 = true;
    },
    videoShow() {
      this.dialogVideo = true;
    },
    close() {
      this.dialog = false;
      this.bank = null;
    },
    closeModalBonos() {
      this.dialogModel = false;
    },
    closeModalVideo() {
      this.dialogVideo = false;
    },
    format(value) {
      const result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getParam1() {
      let token = this.$route.query.token;
      let type_bank = 1;
      this.sendToken(token, type_bank);
    },
    getParam2(params1, params2, params3) {
      this.$swal({
        title: params1,
        text: params2 + " " + params3,
        icon: params1 == "Rechazado" ? "warning" : "success",
        type: params1 == "Rechazado" ? "warning" : "success",
      }).then((result) => {
        if (result.value) {
          this.$router.replace({ query: null });
          location.reload();
        }
      });
    },
    getParam3() {
      let token = this.$route.query.strResponse;
      let type_bank = 3;
      this.sendToken(token, type_bank);
    },
    sendToken(token, bank) {
      if (token.length != 0) {
        axios
          .post("getToken", {
            token: token,
            bank: bank,
          })
          .then((response) => {
            this.$swal({
              title: response.data.FNMSG,
              icon: "success",
              type: "succes",
            }).then((result) => {
              if (result.value) {
                this.$router.replace({ query: null });
                location.reload();
              }
            });
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.msg = error.response.data.errors;
              this.hasError = true;
              this.snackbar = true;
              setTimeout(() => {
                this.hasError = false;
              }, 8000);
            }
          });
      }
    },
  },
};
</script>
<style scoped src="../../assets/css/accountmove.css"></style>
