<template>
  <v-container>
    <v-menu :disabled="disabled" v-model="menu"  offset-y :close-on-content-click="false" transition="scale-transition">
      <!-- Botón activador del menú -->
      <template v-slot:activator="{ on, attrs }">
        <div class="select-container" v-bind="attrs" v-on="on">
          <div class="selected-value"> {{ selectedItem }} </div>
          <v-icon v-if="!disabled" color="white" class="dropdown-icon ml-auto">mdi-chevron-down</v-icon>
        </div>
      </template>
      <!-- Contenido del menú principal -->
      <v-list dense class="custom-list-item">
        <!-- Años principales -->
        <div v-for="(option, index) in options" :key="index" class="custom-select-group">
          <v-list-item class="custom-select-year" @click="toggleSubmenu(index)">
            <v-list-item-title>{{ option.year }}</v-list-item-title>
          </v-list-item>
          <!-- Submenú (Meses) -->
          <v-menu :value="activeSubmenu === index" offset-x :close-on-content-click="true" transition="scale-transition"
            origin="center center">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on"></div>
            </template>
            <v-list class="custom-select-submenu" dense>
              <v-list-item v-for="(month, monthIndex) in option.items" :key="monthIndex" @click="selectOption(month)"
                class="custom-select-suboption">
                <v-list-item-title>{{ `${month.full_name} ${option.year}` }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import MixinService from '@/mixins/service'
import moment from 'moment';

export default {
  props: {
    value: {
      // type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: { 
      default: false,
    },
  },
  mixins: [MixinService],
  data() {
    return {
      menu: false,
      activeSubmenu: null,
      selected: null,
    };
  },
  methods: {
    toggleSubmenu(index) {
      // Activa o desactiva el submenú correspondiente
      this.activeSubmenu = this.activeSubmenu === index ? null : index;
    },
    selectOption(item) {
      // Guarda la selección final y cierra los menús
      this.selected = `${item.full_name} ${item.year}`;
      this.menu = false;
      this.activeSubmenu = null;
      this.$emit("input", item);
    },
    groupByYear(data) {
      return Array.isArray(data)
        ? Object.values(
          data.reduce((acc, item) => {
            const { year } = item;
            if (!acc[year]) {
              acc[year] = { year, items: [] };
            }
            acc[year].items.push(item);
            return acc;
          }, {})
        ).sort((a, b) => b.year - a.year)
        : [];
    },
  },
  computed: {
    selectedItem() {
      if (this.selected) return this.selected;
      if (this.options) {
        return this.options[0].items[0].date;
      }
      return this.placeholder;
    },
  },
  created() {
    const today = parseInt(moment().format('M'));
    const months = 24 + today;
    const years = this.calcularUltimosMeses(months);
    this.options = this.groupByYear(years);
  }
};
</script>

<style scoped>
.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#AD2020, #fa4848);
  border: 2px solid #d32f2f;
  color: #d32f2f;
  padding: 2px 15px;
  border-radius: 12px;
  cursor: pointer;
  min-width: 120px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color:white;
}

.select-container:hover {
  background-color: #d32f2f;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.select-container:hover .dropdown-icon {
  transform: rotate(180deg);
}

.custom-menu {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.custom-list-item {
  text-align: center;
  padding: 2px 15px;
  padding: 0px;
  border-style: solid;
  border-color: gray;
  border-width: 0px 0px 1px 0px;
  transition: background-color 0.3s ease;
}

.custom-list-item:hover {
  background-color: #f5f5f5;
}

.custom-select-trigger {
  width: 200px;
  justify-content: start;
  text-transform: none;
  font-size: 16px;
}

.custom-select-options {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.custom-select-group {
  position: relative;
}

.custom-select-year {
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.custom-select-year:last-child {
  border-bottom: none;
}

.custom-select-submenu {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
}

.custom-select-suboption {
  padding: 10px;
  cursor: pointer;
  height: 25px;
}

.custom-select-suboption:hover {
  background-color: #f7f7f7;
}
</style>
